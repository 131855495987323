<template>
  <div class="apparatus">
    <div class="title">Westmount Volunteer Fire Department Apparatus</div>
    <div class="content">
      <div v-for="apparatus in apparatusList" :key="apparatus.id">
        <div class="apparatus-card" style="max-width: 540px;">
          <b-row no-gutters>
            <b-col md="6">
              <b-img fluid
                :src="getImgUrl(apparatus.imageCode)"
                height="210"
              />
            </b-col>
            <b-col md="6">
              <div class="list-group">
                <div class="list-item"><span class="title">{{apparatus.name}}</span></div>
                <div class="list-item"><span class="left">Manufacturer:</span><span class="right">{{apparatus.manufacturer}}</span>
                </div>
                <div class="list-item"><span class="left">Year:</span><span class="right">{{apparatus.year}}</span></div>
                <div class="list-item"><span class="left">Purpose:</span><span class="right">{{apparatus.purpose}}</span>
                </div>
                <div class="list-item"><span class="left">Pump Size:</span><span class="right">{{`${apparatus.pumpSize} GPM`}}</span>
                </div>
                <div class="list-item"><span class="left">Tank Size:</span><span class="right">{{`${apparatus.tankSize} gal`}}</span>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row no-gutters>
            <b-col md="12" class="description">
              {{apparatus.description}}
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import json from "../json/apparatus.json";

export default {
  data() {
    return {
      apparatusList: json
    };
  },

  methods: {
    getImgUrl(pic) {
      return require("../assets/apparatus/" + pic + ".jpg");
    }
  }
};
</script>

<style>
.apparatus {
  width: 100%;
}

.title {
  width: 100%;
  font-weight: bold;
  font-size: 2em;
  margin: 0 0 5px 10px;
}

.content {
  width: 80%;
  margin: 0 auto 1em auto;
}

.apparatus-card {
  margin-top: 0.5em;
  margin-bottom: 1.0em;
  border: 1px solid #aaa;
  padding: 10px;
  box-shadow: 5px 10px #eee;
}

.apparatus-card .list-group {
  padding: 0.5em 0 0 0;
}

.apparatus-card .list-group .list-item {
  border: none;
  line-height: 1.5em;
  padding: 0;
}

.apparatus-card .list-group .list-item .title {
  color: #000;
  font-size: 1.5em;
}

.apparatus-card .list-group .list-item .left {
  color: #777;
  font-weight: bold;
}

.apparatus-card .list-group .list-item .right {
  color: #777;
  margin-left: 0.25em;
}

.apparatus-card .description {
  color: #777;
  margin-left: 0.5em;
}
</style>
